<template>
  <div class="commonCardsRating" data-testid="common-card-rating" v-if="getShowRating">
    <span class="commonCardsRating__star">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M7.01073 1.25621L8.03766 3.22091C8.1777 3.48882 8.55113 3.75673 8.86233 3.80138L10.7217 4.09907C11.912 4.29256 12.1921 5.11118 11.3363 5.9298L9.88926 7.32146C9.64809 7.55216 9.50805 8.00613 9.58585 8.33357L9.99818 10.0527C10.3249 11.4071 9.57029 11.9355 8.31774 11.2285L6.57506 10.2387C6.25608 10.0601 5.74261 10.0601 5.42364 10.2387L3.67318 11.2211C2.42063 11.9281 1.66598 11.3997 1.99274 10.0452L2.40507 8.32613C2.48287 8.00613 2.34283 7.55216 2.10165 7.31402L0.662385 5.93724C-0.193397 5.11862 0.0866771 4.29256 1.27699 4.10651L3.13637 3.80883C3.44756 3.75673 3.821 3.49626 3.96103 3.22835L4.98797 1.26366C5.54034 0.192004 6.45836 0.192004 7.01073 1.25621Z"
          fill="#FFB800"
        />
      </svg>
    </span>
    <span class="commonCardsRating__number">{{ value || "0.0" }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getShowRating: "config/getShowRating",
    }),
  },
  props: {
    value: {
      type: String,
      require: true
    },
  },
};
</script>

<style lang="scss" scoped>
.commonCardsRating {
  color: #ffb800;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 20px;

  &__star {
    position: relative;
    top: -1px;
    ::v-deep .svgCss svg {
      width: 12px;
      height: 12px;
      path {
        fill: #ffb800;
      }
    }
  }
  &__number {
    position: relative;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
